import Vue from 'vue'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import i18n from '@/i18n'
import enterpriseApi from '@/api/enterprise.api'
import reminderApi from '@/api/reminder.api'
import personApi from '@/api/person.api'
import thingApi from '@/api/thing.api'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import { getDateTimeRange, ISODateFromUnixTimestamp, getChildrenFromList } from '@/tools/functions'

export default {
  name: 'DoneRemindersReport',
  components: {
    SelectorComponent,
    BreadcrumbComponent
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    loadingTable: false,
    showSelector: false,
    selectorModel: {
      showClose: true,
      filters: [
        {
          id: 'object-selector',
          name: i18n.t('reminder.objects'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'type-selector',
          name: i18n.t('reminder.selectTypes'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      disableGetReportButton: true,
      showDateAndTimeSelector: true,
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: false,
      autoReport: false,
      autoReportMinutes: 10
    },
    settingDisabled: false,
    loadingSpinner: true,
    intervalId: null,
    disableRefreshTable: true,
    tabs: [
      {
        id: 'tab-reminder-history',
        name: i18n.t('reminder.historyReport')
      }
    ],
    tabSelected: 'tab-reminder-history',
    breadcrumbButtons: []
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
    this.breadcrumbButtons.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Inicializo los datos del selector
    await this.initializeSelector()
    // Verifico si hay una clave de reporte como parámetro y cargo ese reporte
    // const reportKey = this.$route.query.key
    // this.getData(reportKey)
  },
  methods: {
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    tabSelectedMethod () {
    },
    updateFilterModelDate (filterData) {
      const filterDataTimeRange = filterData.dateTimeRange || null
      const filterDataTimeType = filterData.customDateTimeRangeType || null

      if (filterDataTimeRange == null && filterDataTimeType == null) {
        if (filterData.from || filterData.to) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
        }
      } else {
        this.selectorModel.selectedDateAndTimeRange = filterDataTimeRange
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterDataTimeType
      }

      if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from)
        const toDateData = ISODateFromUnixTimestamp(filterData.to)

        // Carga fechas en formato string desde el timestamp
        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      const allPeople = (await personApi.getAll()).data
      const allThings = (await thingApi.getNested()).data
      const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
      const enterprises = (await enterpriseApi.getMultiple([enterpriseId])).data
      const types = (await reminderApi.getAllTypesByEnterprises(enterprises.map(e => e.id))).filter(t => t.visible).map(t => ({ id: t._id, name: t.name }))
      const plans = (await reminderApi.getAllPlansByEnterprises(enterprises.map(e => e.id))).filter(t => t.visible).map(t => ({ id: t._id, name: t.name }))
      this.selectorModel.filters[0].data.cleanAndUpdate(allPeople.concat(enterprises).concat(allThings))
      this.selectorModel.filters[1].data.cleanAndUpdate(types.concat(plans))
      this.showSelector = true
    },
    /**
     * Para el botón del breadcrumb
     */
    selector () {
      this.showSelector = !this.showSelector
    },
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton () {
      return this.settingDisabled
    },
    /**
     * TO DO
     * Acción del botón para actualizar los datos de la tabla
     */
    refreshTable () {
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      this.getDataNotDeferred(true)
    },
    getReport (eventData) {
      this.loadingTable = true
      this.getDataNotDeferred()
      if (eventData.autoReport) {
        clearInterval(this.intervalId)
        const that = this
        this.intervalId = setInterval(
          function () {
            that.getDataNotDeferred(true)
          }, eventData.autoReportMinutes * 60 * 1000)
      }
      this.showSelector = false
    },
    getDataNotDeferred (refresh = false) {
      this.loadingTable = true
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      const conversion = getDateTimeRange(this.selectorModel.selectedDateAndTimeRange, this.selectorModel.selectedDateAndTimeRangeCustomType,
        this.selectorModel.sinceDate, this.selectorModel.sinceTime, this.selectorModel.toDate, this.selectorModel.toTime)
      // para el reporte se envian como filtro solo los idThing, no id de grupo al que pertenece
      let selectedThings = []
      this.selectorModel.filters[0].selectedData.map(thing => {
        if (thing.nodeType === 'GROUP') getChildrenFromList(thing.children, selectedThings)
        else selectedThings.push(thing)
      })
      reminderApi.getHistoryReport(conversion.tFrom, conversion.tTo,
        selectedThings.map(x => x.id),
        this.selectorModel.filters[1].selectedData.map(x => x.id)
      ).then(response => {
        if (!refresh) {
          this.setTableFilterRules(response.headers)
          this.tableHeaders.cleanAndUpdate(response.headers)
          this.setReportDetails()
          this.disableRefreshTable = false
        }
        this.tableData.cleanAndUpdate(response.data)
        this.loadingTable = false
        if (this.loadingSpinner) this.commitVisible({ visible: false })
      })
    },
    /**
     * Para configurar el detalle del reporte
     */
    setReportDetails () {
      const detailData = {
        objects: this.selectorModel.filters[0].selectedData,
        types: this.selectorModel.filters[1].selectedData
      }
      const dataStructure = [
        { detailDataProp: 'objects', propTitle: this.$t('reminder.objects'), propType: 'LIST', propRefs: { list: this.selectorModel.filters[0].selectedData, value: 'id', text: 'name' } },
        { detailDataProp: 'types', propTitle: this.$t('reminder.selectTypes'), propType: 'LIST', propRefs: { list: this.selectorModel.filters[1].selectedData, value: 'id', text: 'name' } }
      ]
      this.setDetails({
        detailData,
        dataStructure
      })
    },
    /**
     * Para setear las reglas de filtrado
     * @param {*} headers
     */
    setTableFilterRules (headers) {
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        that.selectorModel.disableGetReportButton = that.selectorModel.filters[0].selectedData.length === 0
      })
    }
  },
  watch: {
    'selectorModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
