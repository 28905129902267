import { render, staticRenderFns } from "./DoneRemindersReport.vue?vue&type=template&id=b4a3afc8&scoped=true"
import script from "./DoneRemindersReport.js?vue&type=script&lang=js&external"
export * from "./DoneRemindersReport.js?vue&type=script&lang=js&external"
import style0 from "./DoneRemindersReport.vue?vue&type=style&index=0&id=b4a3afc8&prod&scoped=true&lang=scss"
import style1 from "./DoneRemindersReport.vue?vue&type=style&index=1&id=b4a3afc8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a3afc8",
  null
  
)

export default component.exports